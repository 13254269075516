import DateFnsUtils from '@date-io/date-fns';
import {
    Avatar, Box, Button, CircularProgress, createStyles, Grid, IconButton, makeStyles, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    TextField, Theme, withStyles
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../components/Footer/Footer';
import AvatarTooltip from '../components/GlobalStyles/AvatarTooltip';
import StyledCell from "../components/GlobalStyles/StyledCell";
import * as constant from '../constants/Constant';
import clearIcon from '../images/buttonIcons/clearIcon.svg';
import GlobalAdmin from '../images/users/ClientAdmin.svg';
import FileAdmin from '../images/users/FileAdmin.svg';
import ClientAdmin from '../images/users/GlobalAdmin.svg';
import RegularUser from '../images/users/RegularUser.svg';
import SuperAdmin from '../images/users/SuperAdmin.svg';
import { IAdminGetClients } from "../models/UploadLog/AdminGetClients";
import { IGetUploadLogData } from "../models/UploadLog/GetUploadLogResult";
import { IGetUsers } from "../models/UploadLog/GetUsersList";
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import DocumentPDFViewer from './UploadLog/DocumentPDFViewer';
import DownloadDocumentUploadFile from "./UploadLog/DownloadDocumentUploadFile";

const useStyles = makeStyles((theme) => ({
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
        fontSize: 12
    },
    grid: {
        padding: '0px 6px 6px 6px'
    },
    maingrid: {
        borderRadius: '10px',
        marginTop: '-15px'
    },
    textField: {
        width: 230,
        fontSize: 13,
        ['@media (min-width:1390px)']: {
            width: 230,
        },
        ['@media (max-width:1390px)']: {
            maxWidth: 'calc( 400px - 230px)',
            minWidth: 'calc( 400px - 230px)',
        }
    },
    textField1: {
        width: 230,
        fontSize: 13,
        ['@media (min-width:1390px)']: {
            width: 230,
        },
        ['@media (max-width:1390px)']: {
            maxWidth: 'calc( 400px - 230px)',
            minWidth: 'calc( 400px - 230px)',
        }
    },
    filter: {
        marginTop: '15px'
    },
    root: {
        flexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    Title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        borderRadius: '0px 4px 4px 4px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 225px)',
            minHeight: 'calc( 100vh - 225px)',
        },
    },
    IconStyle: {
        height: '14.5px',
        width: '14.5px'
    },
    paper1: {
        border: "2px solid black",
    },
    smallFont: {
        fontSize: 12,
    },
    gridheader: {
        borderRadius: '10px 10px 0px 0px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '3px 8px'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '0px 0px 0px 8px'
    },
    fileDownloadLink: {
        fontSize: 12,
        width: 600,
        color: "blue",
    },
    clientAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    globalAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    regularUser: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    fileAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid gray',
    },
    titlehead: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: '10px',
        marginTop: 15
    },
    ErrorMessageStyle: {
        color: 'red',
        fontSize: 14
    },
    smallfont: {
        fontSize: 13
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
    },
    autoCompleteFont: {
        fontSize: 12,
    },
    outlinedInput1: {
        fontSize: 12,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'white',
            border: 'none',
        },
    },
    gridfooter: {
        borderRadius: '0px 0px 10px 10px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
}))

const usePopoverStyles = makeStyles({
    paper: {
        border: '2px solid black'
    }
});

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            width: "100%",
            height: 48,
        },
    }),
)(TableRow);

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: "", disablePadding: false, label: "", sortable: false, },
        { id: "fullusername", disablePadding: false, label: "USER", sortable: true },
        { id: "clientId", disablePadding: false, label: "CLIENT ID", sortable: true },
        { id: "phase", disablePadding: false, label: "PHASE", sortable: true },
        { id: "uploadDate", disablePadding: false, label: "DATE", sortable: true, marginLeft: "20px" },
        { id: "uploadDate", disablePadding: false, label: "TIME", sortable: true },
        { id: "uploadMethod", disablePadding: false, label: "UPLOAD METHOD", sortable: true },
        { id: "fileName", disablePadding: false, label: "FILE", sortable: true },
        { id: "fileId", disablePadding: false, label: "PREVIEW", sortable: true }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.TableCellStyle} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const Uploadlog = () => {
    const classes = useStyles();
    const popoverClasses = usePopoverStyles();
    const { state, dispatch } = useGlobalState();

    const [clientList, setclientList] = useState<IAdminGetClients[]>([]);
    const [SelectedClientCode, setSelectedClientCode] = useState<any>([]);
    const [ListLog, setListLog] = useState<IGetUploadLogData[]>([]);
    const [SelectActiveUser, setSelectActiveUser] = useState<any>([]);
    const [userList, setuserList] = useState<IGetUsers[]>([]);

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());

    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [adminLevel, setAdminLevel] = useState<number>();
    const [page, setPage] = useState(0);

    const [orderBy, setOrderBy] = useState("UploadDate");
    const [order, setOrder] = useState("desc");

    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [selectedUser, setSelectedUser] = useState(false);
    const [open, setOpen] = useState(false);
    const [isClear, setIsClear] = useState(false);
    const [mapped, setMapped] = useState(false);

    const ClientCode = state.userAccessContext?.clientCodes.join(",");
    const clientsLoading = clientList.length === 0;
    const usersLoading = userList.length === 0;

    const [totalRecords, setTotalRecords] = useState<number>(0);
    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearAllFilters(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setshowProgressBar(true);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet").then((GetUsersList) => {
                        setuserList(GetUsersList?.data);
                    });
                }
                else if (state?.userAccessContext?.role === 1 && state.userAccessContext?.id !== undefined) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + state.userAccessContext?.id).then((GetUserForClient) => {
                        setuserList(GetUserForClient?.data);
                    });
                }
                else if (state?.userAccessContext?.role === 0 && state.userAccessContext?.id !== undefined) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userId=" + state.userAccessContext?.id).then((GetUserForClient) => {
                        setuserList(GetUserForClient?.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                        setclientList(AdminGetClients?.data);
                    });
                }
                else if (state?.userAccessContext?.role === 1 && state.userAccessContext?.id !== undefined) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                        setclientList(AdminGetClients?.data);
                    });
                }
                else if (state?.userAccessContext?.role === 0 && state.userAccessContext?.id !== undefined) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((AdminGetClients) => {
                        setclientList(AdminGetClients?.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])

    function getUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
                "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
                "userid": state?.userAccessContext?.id,
                "clientid": (selectedClient ? (SelectedClientCode === null ? "" : SelectedClientCode?.client_code) : ""),
                "isclientadmin": (state?.userAccessContext?.role === 3 ? 3 : 2),
                "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser?.id) : null),
                "OrderBy": orderBy,
                "orderDirection": order === "desc" ? 1 : 0,
                "StartingRecordNumber": (page * rowsPerPage) + 1,
            };
            await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                setListLog(clientUploadLogList?.data["uploadLog"]);
                setMapped(!clientUploadLogList?.data["uploadLog"].length ? true : false);
                setTotalRecords(clientUploadLogList?.data?.no_of_rec);
            }).finally(() => {
                setshowProgressBar(false);
                setOpen(true);
            });
        })();
    }

    function clientgetUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : !selectedFirstDate && StartDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
                "enddate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : !selectedEndDate && EndDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
                "userid": state.userAccessContext?.id,
                "clientid": (selectedClient ? (SelectedClientCode === null ? ClientCode : SelectedClientCode?.client_code) : ClientCode),
                "isclientadmin": 1,
                "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser?.user_id) : ""),
                "OrderBy": orderBy,
                "orderDirection": order === "desc" ? 1 : 0,
                "StartingRecordNumber": (page * rowsPerPage) + 1,
            };
            await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                setListLog(clientUploadLogList?.data["uploadLog"]);
                setMapped(!clientUploadLogList?.data["uploadLog"]?.length ? true : false);
                setTotalRecords(clientUploadLogList?.data?.no_of_rec);
            }).finally(() => {
                setshowProgressBar(false);
                setOpen(true);
            });
        })();
    }

    function ClientUserUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": (selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : !selectedFirstDate && StartDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
                "enddate": (selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : !selectedEndDate && EndDate ? moment(new Date()).format('YYYY-MM-DD') : ''),
                "userid": (state?.userAccessContext?.id),
                "clientid": (selectedClient ? (SelectedClientCode === null ? ClientCode : SelectedClientCode?.client_code) : ClientCode),
                "isclientadmin": 0,
                "filteruserid": null,
                "OrderBy": orderBy,
                "orderDirection": order === "desc" ? 1 : 0,
                "StartingRecordNumber": (page * rowsPerPage) + 1,
            };
            await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                setListLog(clientUploadLogList?.data["uploadLog"]);
                setMapped(!clientUploadLogList?.data["uploadLog"]?.length ? true : false);
                setTotalRecords(clientUploadLogList?.data?.no_of_rec);
                setOpen(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    useEffect(() => {
        if (state?.userAccessContext?.role === 1 || state?.userAccessContext?.role === 0) {
            setIsClear(true);
        }
        else {
            setIsClear(false);
        }
    }, [isClear])

    useEffect(() => {
        if (state?.userAccessContext?.role === 3) {
            setAdminLevel(3);
        }
        else if (state?.userAccessContext?.role === 2) {
            setAdminLevel(2);
        }
        else if (state?.userAccessContext?.role === 1) {
            setAdminLevel(1);
        }
        else {
            setAdminLevel(0);
        }
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                getUploadLogListAsync();
            }
            else if (state?.userAccessContext?.role === 1) {
                clientgetUploadLogListAsync();
            }
            else {
                ClientUserUploadLogListAsync();
            }
        })()
    }, [rowsPerPage, page, orderBy, order]);

    useEffect(() => {
        if (state?.userAccessContext?.role === 3) {
            setAdminLevel(3);
        }
        else if (state?.userAccessContext?.role === 2) {
            setAdminLevel(2);
        }
        else if (state?.userAccessContext?.role === 1) {
            setAdminLevel(1);
        }
        else {
            setAdminLevel(0);
        }
    }, [adminLevel])

    function ClearFilterAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": '',
                "enddate": '',
                "userid": (isClear ? state?.userAccessContext?.id : ""),
                "clientid": (state?.userAccessContext?.role === 1 || state?.userAccessContext?.role === 0 ? ClientCode : ''),
                "isclientadmin": (state?.userAccessContext?.role === 0 || state?.userAccessContext?.role === 1 ? 0 : state.userAccessContext?.role),
                "filteruserid": null,
                "OrderBy": orderBy,
                "orderDirection": order === "desc" ? 1 : 0,
                "StartingRecordNumber": (page * rowsPerPage) + 1,
            };

            await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                setListLog(clientUploadLogList?.data["uploadLog"]);
                setMapped(!clientUploadLogList?.data["uploadLog"]?.length ? true : false);
                setTotalRecords(clientUploadLogList?.data?.no_of_rec);
                setClearAllFilters(true);
                resetValues();
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    function onUsersSelect(event, value) {
        setSelectActiveUser(value);
        setSelectedUser(true);
        setPage(0);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value.id).then((GetUsers) => {
                        setuserList(GetUsers?.data);
                    });
                }
                else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + value.user_id).then((GetUserForClient) => {
                        setuserList(GetUserForClient?.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function onClickFilter() {
        dispatch({ type: GlobalStateAction.Busy });
        (async () => {
            if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                getUploadLogListAsync();
            }
            else if (state?.userAccessContext?.role === 1) {
                clientgetUploadLogListAsync();
            }
            else {
                ClientUserUploadLogListAsync();
            }
        })()
    }

    function onClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone);
        setSelectedClient(true);
        setPage(0);
        (async () => {
            try {
                if (state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((GetAdminClientCodes) => {
                        setclientList(GetAdminClientCodes.data);
                    });
                }
                else if ((state?.userAccessContext?.role === 1 || state?.userAccessContext?.role === 0) && state.userAccessContext?.id !== undefined) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${state.userAccessContext?.id}`).then((GetUsersClientCodes) => {
                        setclientList(GetUsersClientCodes?.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    function resetValues() {
        setStartDate(null);
        setEndDate(null);
        setPage(0);
        setSelectedFirstDate(false);
        setSelectedEndDate(false);
        setSelectedClient(false);
        setSelectedUser(false);
        setSelectActiveUser([]);
        setSelectedClientCode([]);
    }

    const formatTime = (timeString: string): string => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Snackbar open={open} className="snackBarStyle" anchorOrigin={{ vertical: 'top', horizontal: 'center', }} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" className="alertStyle">
                        Filter Successfully Triggered!
                    </Alert>
                </Snackbar>
                <Snackbar open={clearAllFilters} className="snackBarStyle" anchorOrigin={{ vertical: 'top', horizontal: 'center', }} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" className="alertStyle">
                        Filter Cleared Successfully!
                    </Alert>
                </Snackbar>

                <CssBaseline />
                {StartDate > EndDate ? <Typography variant='h6' className={classes.ErrorMessageStyle}>End date should be greater than the Start date!</Typography> : ""}
                <div style={{ padding: '7px 6px 0px 7px' }}>
                    <div className={classes.gridheader} >
                        <ul className="divStyle">
                            <li>
                                <Typography variant="h5" gutterBottom className={`${"headertitle"} ${classes.titlehead}`}>
                                    <b>UPLOAD LOG</b>
                                </Typography>
                            </li>
                            <li>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker size="small"
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        autoOk={true}
                                        label="Start Date"
                                        format={DateFormat}
                                        className={classes.textField}
                                        margin="normal"
                                        id="UpLog_StartDate"
                                        value={StartDate}
                                        onChange={handleFirstDateChange}
                                        InputAdornmentProps={{ position: "end" }}
                                        disableFuture={true}
                                        PopoverProps={{ classes: popoverClasses }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput
                                            }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </li>
                            <li>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker size="small"
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        InputAdornmentProps={{ position: "end" }}
                                        autoOk={true}
                                        label="End Date"
                                        className={classes.textField}
                                        format={DateFormat}
                                        margin="normal"
                                        id="UpLog_EndDate"
                                        value={EndDate}
                                        onChange={handleEndDateChange}
                                        disableFuture={true}
                                        PopoverProps={{ classes: popoverClasses }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput
                                            }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </li>
                            <li>
                                {state.userAccessContext?.role === 0 ? "" :
                                    <Autocomplete size="small"
                                        id="UpLog_SelectUsers_DropDown"
                                        autoComplete autoHighlight
                                        classes={{
                                            paper: classes.paper1,
                                            option: classes.smallfont
                                        }}
                                        className={classes.textField1}
                                        options={userList}
                                        getOptionLabel={(option) => option.fullname} clearOnEscape={true}
                                        value={SelectActiveUser}
                                        onChange={onUsersSelect}
                                        loading={usersLoading}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Users" margin="normal" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {usersLoading ? <CircularProgress style={{ height: "25px", width: "25px" }} color="inherit" /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                }
                            </li>
                            <li>
                                <Autocomplete size="small"
                                    id="UpLog_SelectClientCode_Dropdown"
                                    autoComplete autoHighlight={true}
                                    classes={{
                                        paper: classes.paper1,
                                        option: classes.smallfont
                                    }}
                                    className={classes.textField1}
                                    options={clientList}
                                    getOptionLabel={(option) => option.client_code} clearOnEscape={true}
                                    value={SelectedClientCode}
                                    onChange={onClientCodeSelect}
                                    loading={clientsLoading}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Client Code" margin="normal" variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {clientsLoading ? <CircularProgress style={{ height: "20px", width: "20px" }} color="inherit" /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </li>
                            <Hidden mdDown>
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div>
                                        <Button size="small" id="UpLog_Filter_Button" className={classes.buttons} onClick={() => { onClickFilter(); }} variant="contained" color="primary" startIcon={<FilterAltIcon />} disabled={StartDate > EndDate}>
                                            Filter
                                        </Button>
                                    </div>
                                    <div>
                                        <Button size="small" id="UpLog_Filter_Button" startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />} className={classes.buttons} style={{ marginLeft: '3px' }}
                                            onClick={() => { ClearFilterAsync(); }} variant="contained" color="primary" >
                                            Clear Filter
                                        </Button>

                                    </div>
                                </div>
                            </Hidden>
                            <Hidden lgUp>
                                <li>
                                    <AvatarTooltip title="Filter">
                                        <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { onClickFilter(); }} style={{ color: 'blue' }} className={classes.filter} disabled={StartDate > EndDate}>
                                            <FilterListIcon style={{ fontSize: 25 }} />
                                        </IconButton>
                                    </AvatarTooltip>
                                    <AvatarTooltip title="Clear Filter">
                                        <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { ClearFilterAsync(); }} style={{ color: 'blue' }} className={classes.filter}>
                                            <ClearRoundedIcon style={{ fontSize: 25 }} />
                                        </IconButton>
                                    </AvatarTooltip>
                                </li>
                            </Hidden>
                        </ul>
                    </div>
                </div>
                <Grid container alignItems="stretch" className={classes.grid}>
                    <Grid item xs={12} className={classes.gridfooter}>
                        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`} >
                            <Table aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {ListLog?.map(
                                        (row) => {
                                            return (
                                                <StyledTableRow hover>
                                                    <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                                                        {row?.role === 3 ?
                                                            <AvatarTooltip title="Super Admin">
                                                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                                            </AvatarTooltip>
                                                            : row?.role === 2 ?
                                                                <AvatarTooltip title="Global Admin">
                                                                    <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                                                </AvatarTooltip>
                                                                : row?.role === 1 ?
                                                                    <AvatarTooltip title="Client Admin">
                                                                        <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                                                    </AvatarTooltip>
                                                                    : row?.role === 0 ?
                                                                        <AvatarTooltip title="Regular User">
                                                                            <Avatar className={classes.regularUser} src={RegularUser}></Avatar>
                                                                        </AvatarTooltip>
                                                                        : <AvatarTooltip title="File Admin">
                                                                            <Avatar className={classes.fileAdmin} src={FileAdmin}></Avatar>
                                                                        </AvatarTooltip>
                                                        }
                                                    </StyledCell>
                                                    <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                                                        {row?.fullusername}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        {row?.clientId === "MULC" ? "Multi Client" : row.clientId}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        {row?.phase === constant.CONT || row?.phase === constant.PREC || row?.phase === constant.FIRSTSTP ? row?.phase : "Multi Phase"}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        {moment(row?.uploadDate).format(state.GlobalUtils?.settingValue)}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        {moment(row?.uploadTime).format('HH:mm:ss')}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        {(row?.uploadMethod === 0) ? "CSV Upload" : (row?.uploadMethod === 1) ? "Manual Upload" : "Unmapped File Upload"}
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        <DownloadDocumentUploadFile row={row} />
                                                    </StyledCell>
                                                    <StyledCell className={classes.TableCellStyle1}>
                                                        <DocumentPDFViewer row={row} enable={row?.fileName?.split('.').pop() === 'pdf' ? true : row?.fileName?.split('.').pop() === 'PDF' ? true : false} />
                                                    </StyledCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                            {mapped === true ?
                                <Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                    No records to display..
                                </Typography>
                                : null}
                        </TableContainer>
                        <Grid container>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="UpLog_TablePagination"
                                        rowsPerPageOptions={[15]}
                                        component="div"
                                        count={totalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )
}

export default Uploadlog