import { Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Snackbar, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Warning from '../../images/agentUser/deleteWarning.svg';
// Components
import * as constant from '../../constants/Constant';
import { IAccStatus } from '../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { IDebtor } from '../../models/Roll/IDebtor';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
// Models
import Box from '@mui/material/Box';
import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions';
import { dateMasking } from '../../utils/dateMasking';
import useStyles from '../GlobalStyles/AccountDetailCss';
import { InsertTransactionLog } from '../TransactionLogs/InsertTransactionLog';
import CloseAccount from "./CloseAccount";
import CloseAndRemove from "./CloseAndRemove";
import DocumentUpload from './DocumentUpload';
import Submit from "./Submit";

const AccountDetails: React.FC<{ debtor: IDebtor, onRollRemove: () => void, onUndoCloseRemove: () => void, onEroll: () => void, onDelete: () => void, letterDetails: IClosedLetterDetails[], isEnable: IGetRolledPlacement, onHideAll: () => void, BlockAwaitUsers: boolean, GetDebtorLetterDetails: ILetterDetails[], GetAccStatus: IAccStatus, OnGetDetails: () => void }> = (props) => {
    const { debtor, onRollRemove, onUndoCloseRemove, BlockAwaitUsers, isEnable } = props
    const theme = useTheme();
    const classes = useStyles()
    const falseObject = {
        "DocUpload": false,
        "CloseRemove": false,
        "CloseAcc": false,
        "SubmitValue": false
    }
    const { state, dispatch } = useGlobalState();
    const [object, setObject] = useState(falseObject);

    const [ERoll, setERoll] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [disable, setDisable] = useState(false);
    const [ERollUndo, setERollUndo] = useState(false);
    const [paymentVal, setPaymentVal] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [paymentSubmit, setPaymentSubmit] = useState(false);
    const [ConfirmDialog, setConfirmDialog] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [checkFullAmount, setCheckFullAmount] = useState(false);
    const [earlyButtonLoading, setEarlyButtonLoading] = useState(false);

    const [service, setService] = useState('');
    const [inputVal, setInputVal] = useState<any>('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [latePaymentAmount, setLatePaymentAmount] = useState<any>('');
    const [lateAdjustmentAmount, setLateAdjustmentAmount] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>('');
    const [principalAdjustmentAmount, setPrincipalAdjustmentAmount] = useState<any>('');

    const [EarlyRollRolled, setEarlyRollRolled] = useState(props?.debtor?.rolled);
    const [LateFeeDue, setLateFeeDue] = useState<number>(props.debtor.dbT_MISC1_DUE);
    const [updatedLateAmount, setUpdatedLateAmount] = useState(props.debtor.dbT_MISC1_DUE);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.debtor.dbT_PRINCIPAL_DUE);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(props.debtor.dbT_PRINCIPAL_DUE);
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(updatedPrincipalAmount + updatedLateAmount);
    const TotalBalance = principalPaymentDue + LateFeeDue;
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [noPaymentMadeCheck, setNoPaymentMadeCheck] = useState<boolean>(false);
    const [documentNotAvailable, setDocumentNotAvailable] = useState<boolean>(false);

    const handleGetStatus = () => {
        props.OnGetDetails();
    }

    const handleConfirmDialogCLose = () => {
        setConfirmDialog(false);
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setButtonLoading(false);
        setEarlyButtonLoading(false);
    }

    const handleCheckFullAmountDialog = () => {
        setPaymentVal(true);
        setCheckFullAmount(false);
        setButtonLoading(false);
    }

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setDisable(true);
        setPrincipalPaymentAmount(recursiveRemove(value, debtor.dbT_PRINCIPAL_DUE));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtor.dbT_PRINCIPAL_DUE ? value : principalAdjustmentAmount != 0 || lateAdjustmentAmount != 0 || latePaymentAmount != 0);
    };

    const handleFlatfeeValueChange = (value: string): void => {
        setDisable(true);
        setLatePaymentAmount(recursiveRemove(value, debtor.dbT_MISC1_DUE));
        setInputVal(Number(value) > 0 && parseFloat(value) <= props.debtor.dbT_MISC1_DUE ? value : lateAdjustmentAmount != 0 || principalAdjustmentAmount != 0 || principalPaymentAmount != 0);
    };

    const PricipalAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setPrincipalAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : principalPaymentAmount != 0 || latePaymentAmount != 0 || lateAdjustmentAmount != 0);
    };

    const LateAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setLateAdjustmentAmount(recursiveRemove(value, debtor.dbT_MISC1_DUE));
        setInputVal((Number(value) > 0 || Number(value) < 0) ? value : latePaymentAmount != 0 || principalPaymentAmount != 0 || principalAdjustmentAmount != 0);
    };

    function emptyCheck() {
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
        setButtonLoading(false);
        setEarlyButtonLoading(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setERoll(false);
        setPaymentSubmit(false);
        setERollUndo(false);
    };

    const handleCheckboxChange = (event) => {
        setBtnDisabled(event.target.checked);
        setNoPaymentMadeCheck(event.target.checked);
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        let principal_amount_due = debtor.dbT_PRINCIPAL_DUE;
        for (let account of placedAccounts) {
            if (account.prcpl_Adj_Amt && account.prpcl_Pay_Amt === 0) {
                principal_amount_due = ((principal_amount_due) + (account.prpcl_Pay_Amt + account.prcpl_Adj_Amt));
            }
            else if (account.prcpl_Adj_Amt > 0 && account.prpcl_Pay_Amt > 0) {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
            else {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
        }
        let late_fee_due = debtor.dbT_MISC1_DUE;
        for (let account of placedAccounts) {
            if (account.fee_Adj_Amt && account.fee_Pay_Amt === 0) {
                late_fee_due = ((late_fee_due) + (account.fee_Pay_Amt + account.fee_Adj_Amt));
            }
            else if (account.fee_Adj_Amt > 0 && account.fee_Pay_Amt > 0) {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
            else {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
        setLateFeeDue(Number(late_fee_due.toFixed(2)));
    }, [placedAccounts])

    useEffect(() => {
        //Principal Amount
        let Adjustment = Number(Number(principalAdjustmentAmount)?.toFixed(2));
        let principal = Number((principalPaymentDue - principalPaymentAmount)?.toFixed(2));

        //LateFee
        let LateAdjustment = Number(Number(lateAdjustmentAmount)?.toFixed(2));
        let LateFee = Number((LateFeeDue - latePaymentAmount)?.toFixed(2));

        setUpdatedPrincipalAmount(principal + Adjustment);
        setUpdatedLateAmount(LateFee - LateAdjustment);
    }, [LateFeeDue, principalPaymentDue, principalPaymentAmount, principalAdjustmentAmount, latePaymentAmount, lateAdjustmentAmount])

    useEffect(() => {
        setUpdatedTotalAmount(Number((updatedPrincipalAmount + updatedLateAmount).toFixed(2)));
        (updatedPrincipalAmount < 0 || updatedLateAmount < 0) ? setIsValid(false) : setIsValid(true);
    }, [updatedPrincipalAmount, updatedLateAmount])


    async function CheckPreviousPayment(dbt_NO: string) {
        setButtonLoading(true);
        // Check if the full account balance is adjusted to zero without payment.
        if (updatedTotalAmount === 0.00 && ((principalPaymentAmount === "0.00" || principalPaymentAmount === "") && (latePaymentAmount === "" || latePaymentAmount === "0.00"))) {
            setCheckFullAmount(true);
        } else {
            setCheckFullAmount(false);
            // Check if payment matches previous pending payment.
            dispatch({ type: GlobalStateAction.Busy });
            const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${dbt_NO}`);
            if (response.data.length === 0 || noPaymentMadeCheck ? true : false) {
                submitPayment();
            } else {
                setConfirmDialog(true);
            }
        }
    }

    const handleChange = (event) => {
        setBtnDisabled(event.target.checked);
        setDocumentNotAvailable(event.target.checked);
    }

    const submitPayment = () => {
        (async () => {
            if (!btnDisabled) {
                let request = {
                    "dbt_No": props.debtor.dbT_NO,
                    "prcpl_Pay_Amt": principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount),
                    "prcpl_Adjst_Amt": principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount),
                    "fee_Pay_Amt": latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount),
                    "fee_Adjst_Amt": lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount)),
                    "user_Id": state?.userAccessContext?.id
                };
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Payment", operationText: `Payment to Principal: $${principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount)},Payment to Late Fee: $${latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount)},Adjustment to Principal: $${principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount)},Adjustment to Late Fee: $${lateAdjustmentAmount === "" ? 0.00 : (-parseFloat(lateAdjustmentAmount))}`
                };
                feedOperation.push(operations);

                await usePost<any>('Account/SubmitPayment', request);
                hidenotificationForAllUsers();
                GetPayment();
                InsertTransactionLog(state?.userAccessContext?.id, props.debtor.dbT_NO, feedOperation);
                setDisable(true);
                setButtonLoading(false);
                setPaymentVal(false);
            }
            else if (!documentNotAvailable) {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: 'No Payment Received'
                };
                feedOperation.push(operations);

                InsertTransactionLog(state?.userAccessContext?.id, props.debtor.dbT_NO, feedOperation);

                let request = {
                    "dbtNumber": debtor.dbT_NO,
                    "close_acct_note": null,
                    "close_acct_bal": null,
                    "close_acct_reason": "No Payment Received",
                    "user_Id": state?.userAccessContext?.id,
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                await axios.post(`${constant.NoPaymentMade}/${debtor.dbT_NO}?`);
                hidenotificationForAllUsers();
                setBtnDisabled(false);
                setPaymentVal(false);
            } else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: 'Documents not Available'
                };
                feedOperation.push(operations);

                let request = {
                    "dbtNumber": debtor.dbT_NO,
                    "close_acct_note": null,
                    "close_acct_bal": null,
                    "close_acct_reason": "Documents not Available",
                    "user_Id": state?.userAccessContext?.id,
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);
                InsertTransactionLog(state?.userAccessContext?.id, debtor.dbT_NO, feedOperation);
                setBtnDisabled(false);
            }
            props.onHideAll();
        })().finally(() => {
            setPaymentSubmit(true);
            setButtonLoading(false);
            setNoPaymentMadeCheck(false);
            setDocumentNotAvailable(false);
        });
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": 0,
                "userid": state?.userAccessContext?.id,
                "debtorid": debtor.dbT_NO,
                "typeId": 5,
                "userRole": 1
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    const EarlyRoll = () => {
        setEarlyButtonLoading(true);
        (async () => {
            setERoll(true);
            setEarlyRollRolled(true);
            props.onEroll();
            setshowUndoButton(true);
        })().finally(() => {
            setEarlyButtonLoading(false);
        })
    }

    const undoRoll = () => {
        (async () => {
            setEarlyRollRolled(false);
            props.onDelete();
            props.onUndoCloseRemove();
            setshowUndoButton(false);
        })().finally(() => {
            setEarlyButtonLoading(false);
        })
    }

    useEffect(() => {
        (async () => {
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.debtor.dbT_NO}`).then((PlacedAccounts) => {
                setPlacedAccounts(PlacedAccounts.data);
            });
        })()
    }, [])

    const GetPayment = () => {
        (async () => {
            await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.debtor.dbT_NO}`).then((PlacedAccounts) => {
                setPlacedAccounts(PlacedAccounts.data);
            }).finally(() => {
                emptyCheck();
            });
        })()
    }

    useEffect(() => {
        if (debtor.dbT_COMPANY === constant.CONT) {
            setService('Contingency');
        }
        if (debtor.dbT_COMPANY === constant.PREC) {
            setService('Flat Fee');
        }
        else if (debtor.dbT_COMPANY === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    const hideDocNotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": 0,
                "userid": state?.userAccessContext?.id,
                "debtorid": debtor.dbT_NO,
                "typeId": 6,
                "userRole": 1
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root} alignItems='flex-start'>
                <Grid item className={classes.displayFlex} xs={9}>
                    <Grid item xs={'auto'} className={classes.gridFlex}>
                        <Typography variant="h6" component="h6" gutterBottom className={classes.typographyTitleStyle}><b>Current Amount Due </b></Typography>
                        <Grid item xs={12} className={classes.gridFlex} direction='column' >
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle} ><b>Principal</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${principalPaymentDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Late Fee</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>${LateFeeDue.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.displayFlex}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Total</b></Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom className={`${classes.currentamountStyle} ${classes.currentamountStyleColor}`}><b>${TotalBalance.toFixed(2)}</b></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Payment Amount</b>
                        </Typography>
                        <CurrencyInput
                            className={classes.inputField}
                            id="Roll_PrincipalPaymentAmount"
                            placeholder="$ Principal Payment Amount"
                            value={principalPaymentAmount}
                            allowNegativeValue={false}
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={handleOnValueChange}
                            disabled={BlockAwaitUsers || !state?.userAccessContext?.paymentUpdates || buttonLoading}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            onKeyPress={preventMinus}
                            decimalScale={2}
                            maxLength={13}
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            id="Roll_latePaymentAmount"
                            value={latePaymentAmount}
                            allowNegativeValue={false}
                            placeholder="$ Late Fee Payment Amount"
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={handleFlatfeeValueChange}
                            disabled={BlockAwaitUsers || !state?.userAccessContext?.paymentUpdates || buttonLoading}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            onKeyPress={preventMinus}
                            decimalScale={2}
                            maxLength={13}
                        />
                    </Grid>
                    <Grid item xs={4} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}>
                            <b>Adjustment Amount</b>
                        </Typography>
                        <CurrencyInput
                            className={classes.inputField}
                            id="Roll_principalAdjustmentAmount"
                            value={principalAdjustmentAmount}
                            placeholder="$ Principal Adjustment Amount"
                            prefix='$'
                            decimalsLimit={2}
                            onValueChange={PricipalAdjValueChange}
                            disabled={BlockAwaitUsers || !state?.userAccessContext?.paymentUpdates || buttonLoading}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            decimalScale={2}
                            maxLength={13}
                        />
                        <CurrencyInput
                            className={classes.inputField}
                            id="Roll_lateAdjustmentAmount"
                            value={lateAdjustmentAmount}
                            placeholder="$ Late Fee Adjustment Amount"
                            prefix='- $'
                            decimalsLimit={2}
                            onKeyPress={preventMinus}
                            onValueChange={LateAdjValueChange}
                            disabled={BlockAwaitUsers || !state?.userAccessContext?.paymentUpdates || buttonLoading}
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            decimalScale={2}
                            maxLength={13}
                        />
                    </Grid>
                    <Grid item xs={2} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                        <Typography variant="h6" gutterBottom className={classes.typographyTitleStyle}><b>Updated Amount Due</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b> ${updatedPrincipalAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedLateAmount.toFixed(2)}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.updateamountStyleContent}><b>${updatedTotalAmount.toFixed(2)}</b></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} className={`${classes.gridFlex} ${classes.gridLastSpacing}`} direction='column'>
                    <React.Fragment>
                        {debtor.dbT_Status === constant.AwaitingPayment338 || debtor.dbT_Status === constant.AwaitingPayment324 ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={btnDisabled}
                                        onChange={handleCheckboxChange}
                                        name="checkedB"
                                        color="primary"
                                        disabled={!state.userAccessContext?.paymentUpdates}
                                    />
                                } label="No Payment Made"
                            />
                            : debtor.dbT_Status === constant.AwaitingDocs326 ?
                                <FormControlLabel
                                    label="Documents not Available"
                                    control={<Checkbox
                                        disabled={BlockAwaitUsers}
                                        onChange={handleChange} />}
                                />
                                : null
                        }
                        {debtor.isActive !== true && debtor.dbT_COMPANY != constant.CONT && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false) ?
                            < Button id="Inquiry_RollButton" variant="contained" color="primary"
                                startIcon={<ReplayIcon />}
                                size="small"
                                onClick={() => { setClicked(!clicked); EarlyRoll(); }}
                                disabled={!state.userAccessContext?.rollAccounts || EarlyRollRolled || props?.debtor?.rolled || BlockAwaitUsers || props?.GetAccStatus?.isCloseRemove || props?.GetAccStatus?.isClosed || props?.debtor?.removed || props?.isEnable?.status_Id === 3}
                                className={classes.buttonStyle1} >
                                {EarlyRollRolled || props.debtor.rolled ? "Rolled" : "Roll"}
                            </Button>
                            : null
                        }
                        <Typography component="h6" gutterBottom className={classes.typographyFontStyle}><b>Phone Number : {debtor?.deM_PHONE1}</b></Typography>
                        <Typography variant="h5" gutterBottom className={classes.typographyStyleColor}>{service}</Typography>
                        <Typography variant="subtitle1" gutterBottom className={`${classes.typographyStyleColor} ${classes.typographyFontStyle}`}><b>{props.debtor.stS_DESC.replace('|', " ")}</b></Typography>
                        {debtor?.deM_DOB === undefined ? null : debtor?.deM_DOB?.length > 0 ? <Typography variant="subtitle1" align="left" gutterBottom className={`${classes.typographyStyleColor} ${classes.typographyFontStyle}`}><b>DOB: {debtor?.deM_DOB === null ? "" : dateMasking(debtor?.deM_DOB)}</b></Typography> : null}
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Date: {moment(props.debtor.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</b></Typography>
                        <Typography variant="subtitle2" gutterBottom className={classes.typoFontSize}><b>Referral Amount: ${props.debtor.dbT_REFERRAL_AMT.toFixed(2)}</b></Typography>
                    </React.Fragment >
                </Grid >
                <Grid item xs={12} >
                    <Box className={`${classes.displayFlex} ${classes.btnBoxSpacing}`}>
                        <Box className={classes.btnSpacing} width="16%">
                            <Button id="Roll_submit" type="submit" variant="contained" color="primary" disabled={!disable || !state?.userAccessContext?.paymentUpdates || !isValid || !inputVal || BlockAwaitUsers}
                                startIcon={<CheckCircleIcon />} size="small"
                                onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(debtor.dbT_NO); setButtonLoading(true) }}
                                className={`${classes.button} ${classes.buttonBgStyle}`}
                                style={{ background: "#009900" }}>
                                Submit
                                {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} style={{ color: 'white' }} /> : null}
                            </Button>
                        </Box>
                        <Box className={classes.btnSpacing} width="17%">
                            <CloseAccount dbtNumber={props.debtor.dbT_NO} rolled={props.debtor.rolled} removed={props.debtor.removed} clientId={props.debtor.dbT_CLIENT} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} closeReason={placedAccounts[0]?.close_Acct_Reason} closedDetails={props?.GetAccStatus?.isClosed} onUpdate={handleGetStatus} />
                        </Box>
                        {(debtor.isActive === true && debtor.dbT_COMPANY != constant.CONT && (props?.isEnable?.rejectedRoll === false || props?.isEnable?.rejectedRoll === undefined ? true : false)) ?
                            <Box className={classes.btnSpacing} width="16%">
                                <Button id="Roll_EarlyRoll" variant="contained" color="primary" size="small"
                                    startIcon={<ReplayIcon />}
                                    className={`${classes.button} ${classes.btnRollSpacing}`}
                                    onClick={() => { setClicked(!clicked); setshowUndoButton(!showUndoButton); EarlyRoll(); }}
                                    disabled={!state.userAccessContext?.rollAccounts || EarlyRollRolled || props?.debtor?.rolled || BlockAwaitUsers || props?.GetAccStatus?.isCloseRemove || props?.GetAccStatus?.isClosed || props?.debtor?.removed || props?.isEnable?.status_Id === 3}>
                                    {EarlyRollRolled || props?.debtor?.rolled ? "Rolled" : "EarlyRoll"}
                                    {earlyButtonLoading ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
                                </Button>
                                {(EarlyRollRolled || props.debtor.rolled) && (
                                    <IconButton color="primary" aria-label="add to shopping cart"
                                        size="small"
                                        onClick={() => {
                                            setClicked(!clicked);
                                            undoRoll();
                                            setEarlyRollRolled(false);
                                        }}>
                                        <ReplayIcon />
                                    </IconButton>
                                )}
                            </Box >
                            : null
                        }
                        <Box className={classes.btnSpacing} width="17%">
                            <CloseAndRemove dbtNumber={props.debtor.dbT_NO} clientId={props.debtor.dbT_CLIENT} removed={props.debtor.removed} rolled={props.debtor.rolled} onRollRemove={onRollRemove} onUndoCloseRemove={onUndoCloseRemove} dbT_PRINCIPAL_DUE={principalPaymentDue} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} closeReason={placedAccounts[0]?.close_Acct_Reason} closeRemDetails={props?.GetAccStatus?.isCloseRemove} onUpdate={handleGetStatus} />
                        </Box>
                        <Box className={classes.btnSpacing} width="16%">
                            <DocumentUpload clientId={props.debtor.dbT_CLIENT} letterDetails={props.letterDetails} agencyId={props.debtor.dbT_NO} accountId={props.debtor.dbT_CLNT_ACNT1} onHideNotification={hideDocNotificationForAllUsers} BlockAwaitAccess={BlockAwaitUsers} GetDebtorLetterDetails={props.GetDebtorLetterDetails} dbt_status={props.debtor.dbT_Status} />
                        </Box>
                        <Box >
                            <span className={classes.spanErrorSpacing}>
                                {!isValid ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter an amount that is less than the amount due.
                                    </Typography> : null}
                                {paymentVal ?
                                    <Typography variant="h6" className={classes.colorRed} display="block" gutterBottom>
                                        Please enter any payment amounts in the payment boxes.
                                    </Typography> : null}
                            </span>
                        </Box>
                    </Box >
                </Grid >
                <Grid item xs={12}>
                    <Submit clientId={props.debtor.dbT_CLNT_ACNT1} dbtNo={props.debtor.dbT_NO} placedAccounts={placedAccounts} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    <Collapse in={object.SubmitValue} timeout="auto">
                    </Collapse>
                </Grid >
            </Grid >

            <Dialog TransitionComponent={Transition} fullScreen={fullScreen}
                open={ConfirmDialog} keepMounted
                className={classes.dialogboxPaper}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleConfirmDialogCLose}>
                    <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTitle className={classes.titleHeaderOverride}>
                        <img src={Warning} alt="Block_Email" className={classes.ImageWarning} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        One or more of your payments/adjustments matches the value of a pending update.
                        Are you sure, you want to add this additional update to the account?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" variant="contained" startIcon={<CheckCircleIcon />} color="primary" autoFocus
                        onClick={() => { setConfirmDialog(false); submitPayment(); }} className={classes.confirmbtn} >
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus
                        className={classes.cancelbtn} onClick={handleConfirmDialogCLose} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                classes={{ paper: classes.dialogePaper }}
                fullScreen={fullScreen}
                open={checkFullAmount}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCheckFullAmountDialog}>
                    <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.dialogContent} gutterBottom>
                        The account balance is being changed to $0.00.  Was a payment made?<br /><br />
                        If Yes, click OK.<br />
                        If No, Cancel.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" size="small" variant="contained" onClick={handleCheckFullAmountDialog} className={classes.yesButton} color="primary" autoFocus>
                        OK
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButton} onClick={() => { setCheckFullAmount(false); submitPayment(); }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={paymentSubmit} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Transaction Submitted Successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={ERoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Account has been Rolled successfully Kindly Look on the first page.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={ERollUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account has been undone successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}
export default AccountDetails