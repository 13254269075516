import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SearchIcon from '@mui/icons-material/Search';
import {
    Backdrop, Box, Button, Grid, IconButton, InputAdornment, Table, TableBody, TableContainer,
    TableHead, TablePagination, TableSortLabel, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import Footer from '../../../components/Footer/Footer';
import StyledTableRow from '../../../components/GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../../components/GlobalStyles/TableStyledCell';
import SendEmailUserList from '../../../components/NotificationCenterComponent/TrustStatement/SendEmailUserList';
import { IShareTrustStatementResult } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import TrustReportEmailShare from './TrustReportEmailShare';
import { useStylesTrustReport } from './TrustReportShareCss';


const SettingsTrustReportShare = () => {
    const { state } = useGlobalState();
    const classes = useStylesTrustReport();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [shareTrustStatementList, setShareTrustStatementList] = useState<IShareTrustStatementResult[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [searchClientCode, setSearchClientCode] = useState<string>('');
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [totalCount, setTotalCount] = useState(20);
    const [triggerClientCodeSearch, setTriggerClientCodeSearch] = useState(false);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        { id: "clientCode", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: true },
        { id: "createdCode", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: true },
        { id: "items", numeric: false, disablePadding: false, label: "ITEMS", sortable: true },
        // { id: "shareReport", numeric: false, disablePadding: false, label: "SHARE TRUST STATEMENT", sortable: true },
        { id: "history", numeric: false, disablePadding: false, label: "HISTORY", sortable: true },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <StyledTableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell?.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
        );
    }

    useEffect(() => {
        handleGetSubFolder();
    }, [triggerClientCodeSearch])

    const handleGetSubFolder = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                userId: state.userAccessContext?.id,
                folderName: searchClientCode
            }
            await usePost<{ getShareTrustStatementResult: IShareTrustStatementResult[], totalCount: number }>("GetShareTrustStatementDetails", request).then((r) => {
                setShareTrustStatementList(r?.data["getShareTrustStatementResult"]);
                setTotalCount(r?.data["totalCount"]);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const initiateClientCodeSearch = () => {
        setShareTrustStatementList([]);
        setTriggerClientCodeSearch(!triggerClientCodeSearch);
    };

    const cancelClientCodeSearch = () => {
        setShowProgressBar(true);
        setSearchClientCode("");
        setShareTrustStatementList([]);
        setTotalCount(0);

        (async () => {
            let request = {
                userId: state.userAccessContext?.id,
                folderName: null
            }
            await usePost<{ getShareTrustStatementResult: IShareTrustStatementResult[], totalCount: number }>("GetShareTrustStatementDetails", request).then((r) => {
                setShareTrustStatementList(r?.data["getShareTrustStatementResult"]);
                setTotalCount(r?.data["totalCount"]);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Grid item xs={3}>
                        <Typography variant="h6" mt={0.5} gutterBottom className={`${classes.textStyle} ${"headertitle"}`} p={1}>
                            <b> SHARE TRUST STATEMENT</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="standard-bare" variant="outlined"
                            fullWidth size="small"
                            value={searchClientCode}
                            type='text' placeholder="Search Client Code..."
                            className={classes.searchBox}
                            onChange={e => setSearchClientCode(e.target.value.trim())}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && searchClientCode != "") {
                                    initiateClientCodeSearch(); setShowSearchIcon(false);
                                } else if (event.key === 'Enter' && searchClientCode === "") {
                                    initiateClientCodeSearch(); setShowSearchIcon(true);
                                }
                            }}
                            inputProps={{ maxlength: 255 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton onClick={e => { initiateClientCodeSearch(); setShowSearchIcon(false); }} style={{ padding: 2 }}>
                                                <SearchIcon fontSize={'small'} />
                                            </IconButton>
                                            :
                                            <IconButton onClick={e => { cancelClientCodeSearch(); setShowSearchIcon(true); }} style={{ padding: 2 }}>
                                                <CloseIcon fontSize={'small'} style={{ padding: 2 }} />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TrustReportEmailShare />
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant='contained' color="primary" size="small" component={Link} to="/report"
                            startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                            className={classes.backButton}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={12} borderTop={'1px solid lightgray'}>
                        <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead classes={classes} />
                                <TableBody>
                                    {shareTrustStatementList?.map((r) => (
                                        <StyledTableRow key={r?.subFolderId}>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={`${searchClientCode === "" ? classes.NormalText : r?.subFolderName === null ? null : r?.subFolderName?.toUpperCase().includes(searchClientCode.toUpperCase()) ? classes.TextHighlight : ""}`}>
                                                    {r?.subFolderName}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.NormalText} >
                                                    {r?.createdDate}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.NormalText} >
                                                    {r?.itemCount} items
                                                </Typography>
                                            </StyledTableCell>
                                            {/* <StyledTableCell>
                                                <TrustReportEmailShare />
                                            </StyledTableCell> */}
                                            <StyledTableCell>
                                                <SendEmailUserList clientCode={r?.subFolderName} folderId={r?.subFolderId} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {!ProgressBar && !shareTrustStatementList?.length ?
                                < Typography variant="h6" gutterBottom color={'red'} >
                                    No records to display..
                                </Typography>
                                : null}
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} borderTop={'1px solid lightgray'}>
                        <Grid container spacing={0}>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Report_TablePagination"
                                        component="div"
                                        count={totalCount}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default SettingsTrustReportShare