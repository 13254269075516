import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as constant from '../../../constants/Constant';
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IRollPlacementContract } from "../../../models/Inquiry/IRollPlacementContract";
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { InsertTransactionLog } from '../../TransactionLogs/InsertTransactionLog';
import useStyles from '../NCCcss/NotiCenterCss';

const CloseAndRemove: React.FC<{ dbtNumber: string, dbT_PRINCIPAL_DUE: number, checkPhase: string, clientId: string, Disable: IGetRolledPlacement, onEroll: () => void, onSubmit: () => void, closeSts: boolean, BlockAwaitAccess: boolean, closeReason: string, closeRemDetails: boolean, onGetUpdate: () => void }> = (props) => {
    const { Disable, closeRemDetails } = props
    const classes = useStyles()
    const { state, dispatch } = useGlobalState()
    const [flag, setFlag] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [CloseNote, setCloseNote] = useState<string>("");
    const [closeAcctBal, setcloseAcctBal] = useState<any>();
    const [closeAcctReason, setValue] = useState('other');
    const canCloseRemoveAccount = state.userAccessContext?.closingAccounts;
    const handleDisable = Disable?.rolled;
    const [Removed, setRemoved] = useState(closeRemDetails);
    const [inputVal, setinputVal] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>(0);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.dbT_PRINCIPAL_DUE);
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const [isReasonValid, setReasonValid] = useState('');
    const UserName = state.userAccessContext?.id;
    const [closeRemove, setCloseRemove] = useState(false);
    const [undoCloseRemove, setUndoCloseRemove] = useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleOnValueChange = (value: string): void => {
        setPrincipalPaymentAmount(value === '0' || '' ? setIsValid(true) : value || '');
        setcloseAcctBal(value === '0' || '' ? setIsValid(true) : value || '');
        setinputVal(value === '0' || '' ? setIsValid(true) : value || '');
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setCloseNote("");
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    };

    function emptyCheck() {
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCloseRemove(false);
        setUndoCloseRemove(false);
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)));
    }, [props.dbT_PRINCIPAL_DUE, closeAcctBal, principalPaymentAmount])

    useEffect(() => {
        closeAcctReason == 'Paid' ? ((closeAcctBal > props.dbT_PRINCIPAL_DUE) ? setIsValid(false) : setIsValid(true)) : setIsValid(true)
    }, [updatedPrincipalAmount, closeAcctReason])

    const CloseAccount = () => {
        setButtonLoading(true);
        (async () => {
            let CloseBalance = parseFloat(closeAcctBal);
            let request = {
                "dbtNumber": props.dbtNumber,
                "close_acct_note": CloseNote,
                "close_acct_bal": CloseBalance,
                "close_acct_reason": closeAcctReason,
                "user_Id": UserName,
                "isCloseRemove": true,
                "isClosed": false
            };
            let Principal = ((props.dbT_PRINCIPAL_DUE - CloseBalance).toFixed(2));
            let requestPaid = {
                "prcpl_Pay_Amt": Principal,
                "close_acct_note": CloseNote,
                "dbtNumber": props.dbtNumber,
                "close_acct_reason": closeAcctReason,
                "close_acct_bal": CloseBalance,
                "user_Id": UserName,
                "isCloseRemove": true,
                "isClosed": false
            };
            let requestRollClose = {
                "dbt_No": props.dbtNumber,
                "dbt_Client": props.clientId,
                "user_Id": state.userAccessContext?.id
            };
            if (closeAcctReason == 'Paid') {
                await usePost('Account/ClosedPlacedAccounts', requestPaid);
                await usePost<IRollPlacementContract[]>('RollClose', requestRollClose).finally(() => {
                    setButtonLoading(false);
                });
                AccountCloseRemove();
                setPrincipalAmount(Principal);
                props.onSubmit();
            }
            else {
                await usePost('Account/EmptyPlacedAccounts', request).finally(() => {
                    setButtonLoading(false);
                });
                AccountCloseRemove();
                setRemoved(true);
            }
        })()
    }

    const AccountCloseRemove = () => {
        (async () => {
            props.onEroll();
            setRemoved(true);
            setCloseRemove(false);
            setshowUndoButton(false);
            if (closeAcctReason == 'Paid') {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close & Remove", operationText: `Payment to Principal: $${(props.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)}, Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
            } else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close & Remove", operationText: `Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
            }
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            let request = {
                "DBTNumber": props.dbtNumber,
                "isCloseRemove": true,
                "isClosed": false
            }
            await usePost("Account/PlacedAccounts", request).then((r) => {
                if (r.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: r.statusText });
                }
            });
            let CloseRemove = {
                "dbtNumber": props.dbtNumber
            };
            await usePost('Account/UndoCloseRemove', CloseRemove);
            if (props?.closeReason === "Paid") {
                undoAdjustment(principalAmount);
            }
            setshowUndoButton(false);
            setUndoCloseRemove(false);
            props.onEroll();
            props.onGetUpdate();

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Close & Remove Account", operationText: "Close & Remove Account: Cancelled"
            };
            feedOperation.push(operations);

            InsertTransactionLog(state?.userAccessContext?.id, props?.dbtNumber, feedOperation);
        })()
    }

    const undoAdjustment = (adjustmentAmount: string) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNumber,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": "Principal Payment"
            }
            await usePost<any>("Account/UndoAdjustment", request);
            await useFetch<any>("Account/UndoPaymentAmount");
            props.onSubmit();
        })()
    }

    return (
        <React.Fragment>
            <Button id="I_CloseAndRemove_btn" variant="contained"
                className={`${classes.button} ${classes.buttoncloseSpacing}`}
                size="small"
                startIcon={<ClearIcon />}
                onClick={() => { setOpen(true); }}
                disabled={!canCloseRemoveAccount || props.checkPhase === constant.CONT || handleDisable || Removed || props.closeSts || props.BlockAwaitAccess || closeRemDetails || Disable?.removed || !Disable?.roll} color="primary">
                {Removed || closeRemDetails || Disable?.removed ? "Removed" : "Close & Remove"}
                {buttonLoading === true ? <CircularProgress size="small" style={{ color: 'white' }} className={classes.circleStyle} /> : null}
            </Button>
            {(showUndoButton || Removed || closeRemDetails || Disable?.removed) && (
                Disable?.status_Id === 3 ?
                    null
                    :
                    <IconButton
                        id="I_UndoCAR_btn"
                        color="primary" aria-label="add to shopping cart"
                        onClick={() => {
                            setshowUndoButton(!showUndoButton);
                            setRemoved(false);
                            undoCloseandRemove();
                            setUndoCloseRemove(true);
                        }}
                        disabled={props.BlockAwaitAccess}
                        className={classes.iconSpacingTop}>
                        <ReplayIcon />
                    </IconButton>
            )}

            <Dialog TransitionComponent={Transition} open={open} onClose={handleClose} maxWidth={'md'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        CLOSE AND REMOVE
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error">Please enter a payment amount that is less than the amount due.</Alert> : null}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={7}>
                            <TextField
                                id="I_CAR_Description"
                                placeholder="Why is the account being Closed?"
                                size="medium"
                                multiline
                                rows={7}
                                value={CloseNote} onChange={handleReasonNote}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                className={classes.description}
                            />
                        </Grid>
                        <Grid item xs={5} >
                            <form className={classes.formSpacingLeft}>
                                <CurrencyInput
                                    className={classes.inputField}
                                    id="I_CAR_Balance"
                                    placeholder="$ Current Balance Amount in Your System"
                                    value={closeAcctBal}
                                    prefix='$'
                                    decimalsLimit={2}
                                    onValueChange={handleOnValueChange}
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    onKeyPress={preventMinus}
                                    decimalScale={2}
                                    maxLength={9}
                                />
                            </form>
                            <FormControl component="fieldset" className={classes.radioButtonStyle}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <RadioGroup id="I_CAR_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.radioGroupSpacingTop}>
                                            <FormControlLabel value="Paid" control={<Radio />} label="Paid" />
                                            <FormControlLabel value="Bankruptcy" control={<Radio />} label="Bankruptcy" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RadioGroup id="I_CAR_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={`${classes.radioGroupSpacingTop} ${classes.radioGroupSpacingLeft}`}>
                                            <FormControlLabel value="Disputing" control={<Radio />} label="Disputing" />
                                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions >
                    <Button id="I_CAR_Confirm_btn" variant="contained" className={classes.confirmButton} startIcon={<CheckCircleIcon />}
                        disabled={!inputVal || !closeAcctReason || !isValid || !isReasonValid}
                        onClick={() => { setOpen(false); setFlag(!flag); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); setCloseRemove(true); emptyCheck(); }}>
                        Confirm
                    </Button>
                    <Button id="I_CAR_Close_btn" variant="outlined" onClick={handleClose} className={classes.cancelButton} startIcon={<CancelIcon />}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar className="snackBarStyle" open={closeRemove} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account Close and Removed Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={undoCloseRemove} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Undo Account Close and Removed Successfully!
                </Alert>
            </Snackbar>

        </React.Fragment >
    )
}

export default CloseAndRemove