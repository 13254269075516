import {
    Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel,
    Grid, IconButton, makeStyles, Radio, RadioGroup, Snackbar, TextField, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
// Icons
import ReplayIcon from "@material-ui/icons/Replay";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Models
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccountContract } from "../../../models/Inquiry/IPlacedAccountContract";
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { IAgentTemplatePermission } from '../../../models/AgentInquiry/IAgentTemplatePermission';
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { InsertTransactionLog } from '../../TransactionLogs/InsertTransactionLog';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 7,
        fontSize: 11,
        fontWeight: 'bold'
    },
    root1: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    inputField: {
        height: '45px',
        fontSize: 14,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        width: "300px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    description: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        width: '85%',
        fontSize: 14,
        marginLeft: '30px'
    },
    root2: {
        borderRadius: 8,
        height: "90%",
        marginTop: "7px",
        marginLeft: "-30%"
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    confirmButton: {
        color: "white",
        marginTop: "7px",
        marginRight: "10px",
        float: 'right',
        borderRadius: 20,
        fontSize: 12
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginRight: "25px",
        marginTop: "7px",
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
    formcontrol: {
        marginTop: "0.3cm"
    },
    formcontrol1: {
        marginTop: "0.2cm"
    },
    formcontrol2: {
        marginLeft: '1.4cm',
        marginTop: "0.2cm"
    },
    circleStyle: {
        color: 'white',
        height: "20px",
        width: "20px",
        marginLeft: '10px'
    },
    dialogePaper: {
        minWidth: "800px"
    },
    buttoncloseSpacing: {
        padding: '8px 15px'
    },
}))

const CloseAccount: React.FC<{ dbtNumber: string, dbT_PRINCIPAL_DUE: number, Disable: IGetRolledPlacement, clientId: string, onSubmit: () => void, closeSts: boolean, BlockAwaitAccess: boolean, onUpdate: () => void, closeReason: string, closedDetails: boolean, templateAccess: IAgentTemplatePermission }> = (props) => {
    const { BlockAwaitAccess, closedDetails, templateAccess } = props;
    const { state } = useGlobalState();
    const readOnlyAccess = state?.AgentUserAccessContext?.readOnlyAccess;
    const classes = useStyles();
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [inputVal, setinputVal] = useState<any>('');
    const [CloseNote, setCloseNote] = useState<any>('');
    const [closeAcctBal, setcloseAcctBal] = useState<any>('');
    const [closeAcctReason, setValue] = useState('');
    const canCloseAccount = state?.AgentUserAccessContext?.closeAccounts;
    const [Removed, setRemoved] = useState(closedDetails);
    const UserName = state?.AgentUserAccessContext?.agentUserId;
    const [open, setOpen] = useState(false);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props?.dbT_PRINCIPAL_DUE);
    const [isValid, setIsValid] = useState(true);
    const [isReasonValid, setReasonValid] = useState('');
    const [close, setClose] = useState(false);
    const [undoClose, setUndoClose] = useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    const handleOnValueChange = (value: string): void => {
        setcloseAcctBal(value === '0' || '' ? setIsValid(true) : value || '');
        setinputVal(value === '0' || '' ? setIsValid(true) : value || '');
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    };

    function emptyCheck() {
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
        setClose(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setClose(false);
        setUndoClose(false);
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((props?.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)));
    }, [props?.dbT_PRINCIPAL_DUE, closeAcctBal])

    useEffect(() => {
        closeAcctReason == 'Paid' ? ((closeAcctBal > props?.dbT_PRINCIPAL_DUE) ? setIsValid(false) : setIsValid(true)) : setIsValid(true)
    }, [updatedPrincipalAmount, closeAcctReason])

    const CloseAccount = () => {
        setButtonLoading(true);
        (async () => {
            let CloseBalance = parseFloat(closeAcctBal);
            let request = {
                "dbtNumber": props?.dbtNumber,
                "close_acct_note": CloseNote,
                "close_acct_bal": CloseBalance,
                "close_acct_reason": closeAcctReason,
                "user_Id": UserName,
                "isCloseRemove": false,
                "isClosed": true
            };

            if (closeAcctReason == 'Paid') {
                let Principal = ((props?.dbT_PRINCIPAL_DUE - CloseBalance).toFixed(2));
                let requestPaid = {
                    "prcpl_Pay_Amt": Principal,
                    "close_acct_note": CloseNote,
                    "dbtNumber": props?.dbtNumber,
                    "close_acct_reason": closeAcctReason,
                    "close_acct_bal": CloseBalance,
                    "user_Id": UserName,
                    "isCloseRemove": false,
                    "isClosed": true
                };
                await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', requestPaid).finally(() => {
                    setButtonLoading(false);
                });
                props.onSubmit();
                AccountClosed();
                setRemoved(true);
                setPrincipalAmount(Principal);
                setClose(true);
            }
            else {
                await usePost<IPlacedAccountContract[]>('Account/EmptyPlacedAccounts', request).finally(() => {
                    setButtonLoading(false);
                });
                AccountClosed();
                setRemoved(true);
                setClose(true);
            }
        })()
    }

    const AccountClosed = () => {
        (async () => {
            props.onUpdate();
            setRemoved(true);
            setshowUndoButton(!showUndoButton);

            if (closeAcctReason == 'Paid') {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Payment", operationText: `Payment to Principal: $${(props?.dbT_PRINCIPAL_DUE - closeAcctBal).toFixed(2)}, Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, props?.dbtNumber, feedOperation);
            } else {
                let feedOperation = [];
                let operations: IInsertTransactionLog = {
                    operationName: "Close Account", operationText: `Closing Note: ${CloseNote}, Closing Balance: $${closeAcctBal}, Closing Reason: ${closeAcctReason}`
                };
                feedOperation.push(operations);
                InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, props?.dbtNumber, feedOperation);
            }
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            let request = {
                "DBTNumber": props?.dbtNumber,
                "isCloseRemove": false,
                "isClosed": true
            }
            await usePost("Account/PlacedAccounts", request);
            if (props?.closeReason === "Paid") {
                undoAdjustment(principalAmount);
            }
            props.onUpdate();
            setshowUndoButton(false);
            setUndoClose(true);

            let feedOperation = [];
            let operations: IInsertTransactionLog = {
                operationName: "Undo Closed Account", operationText: "Close Account: Cancelled"
            };
            feedOperation.push(operations);
            InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, props?.dbtNumber, feedOperation);
        })()
    }

    const undoAdjustment = (adjustmentAmount: number) => {
        (async () => {
            let request = {
                "Dbt_No": props.dbtNumber,
                "AdjustmentAmount": adjustmentAmount,
                "AdjustmentType": "Principal Payment"
            }
            await usePost<any>("Account/UndoAdjustment", request);
            await useFetch<any>("Account/UndoPaymentAmount");
            props.onSubmit();
        })()
    }

    return (
        <React.Fragment>
            <Button id="Inquiry_closeAccount" variant="contained" onClick={() => { setOpen(true); }}
                className={`${classes.button} ${classes.buttoncloseSpacing}`}
                startIcon={<AccountBalanceIcon />}
                disabled={!canCloseAccount || Removed || props.closeSts || BlockAwaitAccess || closedDetails || templateAccess?.readOnly || readOnlyAccess} color="primary" size="small"
            >
                {Removed || closedDetails ? "Account Closed" : "Close Account"}
                {buttonLoading === true ? <CircularProgress size="small" className={classes.circleStyle} /> : null}
            </Button>
            {(Removed || closedDetails) && (
                props?.Disable?.status_Id === 3 ? null :
                    <IconButton
                        id="Inquiry_UndoCloseAccount"
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={() => {
                            setshowUndoButton(!showUndoButton);
                            setRemoved(false);
                            undoCloseandRemove();
                            setUndoClose(true);
                        }}
                        disabled={BlockAwaitAccess || templateAccess?.readOnly}>
                        <ReplayIcon />
                    </IconButton>
            )}
            <Snackbar className="snackBarStyle" open={close} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Account Closed Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={undoClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Undo Close Account Successfully!
                </Alert>
            </Snackbar>


            <Dialog TransitionComponent={Transition} open={open} keepMounted onClose={handleClose}
                classes={{ paper: classes.dialogePaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CLOSE ACCOUNT
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error" className="snackBarStyle1">
                            Please enter a payment amount that is less than the amount due.
                        </Alert>
                        : null}
                </DialogTitle>
                <Grid container spacing={0}>
                    <Grid item xs={7}>
                        <TextField
                            id="I_CA_Description"
                            placeholder="Why is the account being Closed?"
                            size="medium"
                            multiline
                            rows={6}
                            value={CloseNote} onChange={handleReasonNote}
                            variant="outlined"
                            inputProps={{
                                maxLength: 256
                            }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.description}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <form >
                            <CurrencyInput
                                className={classes.inputField}
                                id="I_CA_Balance"
                                placeholder="$ Current Balance Amount in Your System"
                                value={closeAcctBal}
                                prefix='$'
                                decimalsLimit={2}
                                onValueChange={handleOnValueChange}
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onKeyPress={preventMinus}
                                decimalScale={2}
                                maxLength={13}
                            />
                        </form>
                        <FormControl component="fieldset" className={classes.formcontrol} >
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <RadioGroup id="I_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol1} >
                                        <FormControlLabel value="Paid" control={<Radio size="small" />} label="Paid" />
                                        <FormControlLabel value="Bankruptcy" control={<Radio size="small" />} label="Bankruptcy" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <RadioGroup id="I_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange} className={classes.formcontrol2}>
                                        <FormControlLabel value="Disputing" control={<Radio size="small" />} label="Disputing" />
                                        <FormControlLabel value="Other" control={<Radio size="small" />} label="Other" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
                <DialogActions >
                    <Button id="I_CA_Confirm" variant="contained" size="small" className={classes.confirmButton}
                        style={{ backgroundColor: !inputVal || !isValid || !isReasonValid ? "#e0e0e0" : "green" }}
                        startIcon={<CheckCircleIcon />}
                        onClick={() => { setOpen(false); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); emptyCheck(); }}
                        disabled={!inputVal || !isValid || !isReasonValid}>
                        Confirm
                    </Button>
                    <Button id="I_CA_Close" variant="outlined" className={classes.cancelButton}
                        size="small" startIcon={<CancelIcon />}
                        onClick={() => { handleClose(); setcloseAcctBal(''); }} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default CloseAccount